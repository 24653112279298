import React, { useContext, useEffect } from 'react';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { BasicButton } from '../../../components/common/Button';
import { InputWord } from '../../../components/common/Input';
import { ToastQueueRefContext } from '../../../components/common/Toast';
import FormHeader from '../../../components/forms/FormHeader';
import { connexionType } from '../../../schema/LoginSchema';
import AuthService from '../../../services/auth.service';
import UsersService from '../../../services/users.service';

type LoginFormProps = {
  setPageStep: React.Dispatch<React.SetStateAction<string>>;
  setSkemeetUserInfos: React.Dispatch<
    React.SetStateAction<{ temporaryAccessToken: string; firstname: string; lastname: string; cellPhone: string }>
  >;
  setTokenAndRedirect: (accessToken: string, refreshToken: string) => void;
  connexionMethods: UseFormReturn<{ nickNameOrEmail: string; password: string }, undefined>;
  setIsDoubleAuthEmail: (isDoubleAuthEmail: boolean) => void;
};

const LoginForm = ({ setPageStep, setSkemeetUserInfos, setTokenAndRedirect, connexionMethods, setIsDoubleAuthEmail }: LoginFormProps) => {
  const navigate: NavigateFunction = useNavigate();
  const searchQueryParams = useSearchParams()[0];
  const toastQueueRef = useContext(ToastQueueRefContext);
  const authService = new AuthService();
  const usersService = new UsersService();

  const getProSanteConnectAuthenticationUrl = async () => {
    try {
      const data = await authService.getProSanteConnectLink();
      document.location.href = data.authenticationUrl;
    } catch (error) {
      setPageStep('error');
    }
  };

  const getFranceConnectAuthenticationUrl = async () => {
    try {
      const data = await authService.getFranceConnectLink();

      localStorage.setItem('FranceConnectState', data.state);
      localStorage.setItem('FranceConnectNonce', data.nonce);

      document.location.href = data.authenticationUrl;
    } catch (error) {
      setPageStep('error');
    }
  };

  const loginWithForm = async ({ nickNameOrEmail, password }: connexionType) => {
    const loginReturn = await authService.login({
      nickNameOrEmail,
      password,
      fromSkemeet: localStorage.getItem('redirectUrl')?.includes(import.meta.env.VITE_SKEMEET_FRONT_URL) ? true : false,
    });

    if (loginReturn.changeSkemeetPassword) {
      setSkemeetUserInfos({
        temporaryAccessToken: loginReturn.temporaryAccessToken ? loginReturn.temporaryAccessToken : '',
        firstname: loginReturn.firstname ? loginReturn.firstname : '',
        lastname: loginReturn.lastname ? loginReturn.lastname : '',
        cellPhone: loginReturn.cellPhone ? loginReturn.cellPhone : '',
      });

      setPageStep('updateSkemeetPassword');
    } else if (loginReturn.oneTimePasswordActivated) {
      setPageStep('connexionStepTwo');
      setIsDoubleAuthEmail(false);
    } else if (loginReturn.doubleAuthEmailActivated) {
      setPageStep('connexionStepTwo');
      setIsDoubleAuthEmail(true);
    } else {
      setTokenAndRedirect(loginReturn.accessToken, loginReturn.refreshToken);
    }
  };

  const validateAccount = async (mailActivatedToken: string) => {
    await usersService.validateAccount(mailActivatedToken);

    toastQueueRef?.current?.addToast({
      timer: 10_000,
      description: 'Votre compte a été validé avec succès. Vous pouvez maintenant vous connecter.',
      type: 'SUCCESS',
    });
  };

  const mergeAccount = async (mergeAccountToken: string) => {
    await authService.fcPscMergeAccount(mergeAccountToken);

    toastQueueRef?.current?.addToast({
      timer: 10_000,
      description: 'Votre compte a été associé avec succès. Vous pouvez maintenant vous connecter.',
      type: 'SUCCESS',
    });
  };

  useEffect(() => {
    const mailActivatedToken = searchQueryParams.get('mailActivatedToken');
    if (mailActivatedToken) {
      validateAccount(mailActivatedToken);
    }

    const mergeAccountToken = searchQueryParams.get('mergeAccountToken');
    if (mergeAccountToken) {
      mergeAccount(mergeAccountToken);
    }
  }, []);

  return (
    <>
      <FormHeader
        topLabel="Vous n'avez pas de compte ?"
        actionTopLabel='Inscrivez-vous ici'
        actionTopOnClick={() =>
          navigate(
            localStorage.getItem('redirectUrl') ? `/subscription?redirectUrl=${localStorage.getItem('redirectUrl')}` : `/subscription`,
          )
        }
        title='Heureux de vous revoir !'
        proSanteConnect={true}
        franceConnect={import.meta.env.VITE_APP_URL.includes('.dev') || import.meta.env.VITE_APP_URL.includes('localhost')}
        getProSanteConnectAuthenticationUrl={getProSanteConnectAuthenticationUrl}
        getFranceConnectAuthenticationUrl={getFranceConnectAuthenticationUrl}
      />

      <div className='mb-2 mt-4 text-[1rem] font-semibold'>Avec une adresse mail</div>

      <FormProvider {...connexionMethods}>
        <form onSubmit={connexionMethods.handleSubmit(loginWithForm)}>
          <Controller
            name='nickNameOrEmail'
            control={connexionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Adresse mail ou identifiant'
                  type='text'
                  placeholder='Adresse mail ou identifiant'
                  actionLabel={
                    <span
                      className='cursor-pointer font-medium text-green-secondary hover:font-semibold hover:underline'
                      onClick={() =>
                        navigate(
                          localStorage.getItem('redirectUrl')
                            ? `/reset-nickname?redirectUrl=${localStorage.getItem('redirectUrl')}`
                            : `/reset-nickname`,
                        )
                      }
                    >
                      Identifiant oublié ?
                    </span>
                  }
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                  // errorMessage={<>L'identifiant ne correspond à aucun compte. <span className="font-bold">Veuillez <span className="underline cursor-pointer" onClick={() => setPageStep(pageStep.includes('Participant') ? 'subscriptionParticipant' : 'subscription')}>créer un compte</span></span>.</>}
                />
              </>
            )}
          />
          <Controller
            name='password'
            control={connexionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Mot de passe'
                  type='password'
                  placeholder='Mot de passe'
                  actionLabel={
                    <span
                      className='cursor-pointer font-medium text-green-secondary hover:font-semibold hover:underline'
                      onClick={() =>
                        navigate(
                          localStorage.getItem('redirectUrl')
                            ? `/reset-password?redirectUrl=${localStorage.getItem('redirectUrl')}`
                            : `/reset-password`,
                        )
                      }
                    >
                      Mot de passe oublié ?
                    </span>
                  }
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <BasicButton label='Se connecter' fullWidth={true} isSubmitButton={true} />
        </form>
      </FormProvider>
    </>
  );
};

export default LoginForm;
