import { FC } from 'react';
import * as Icon from '../icons';

type InfoProps = {
  color?: string;
  title: string;
  content: string;
  className?: string;
};

const Info: FC<InfoProps> = ({ color = '', title, content, className = '' }) => {
  const hexToRgba = (hex: string, opacity: number) => {
    const cleanHex = hex.replace('#', '');
    const bigint = parseInt(cleanHex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  return (
    <div
      className={`flex items-center justify-center space-x-3 rounded-lg border-l-4 px-4 py-3 text-sm text-blue-primary ${className}`}
      style={{ borderColor: color, backgroundColor: hexToRgba(color, 0.1) }}
    >
      <Icon.Info className='w-20' color={color} />
      <div>
        <p className='mb-1 font-semibold'>{title}</p>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default Info;
