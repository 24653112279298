import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext } from 'react';
import { Controller, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { BasicButton } from '../../../components/common/Button';
import { InputWord } from '../../../components/common/Input';
import FormHeader from '../../../components/forms/FormHeader';
import { connexionStepTwoSchema, connexionStepTwoType } from '../../../schema/LoginSchema';
import AuthService from '../../../services/auth.service';
import { ToastQueueRefContext } from '../../../components/common/Toast';
import * as Icon from '../../../components/icons';

type TFAFormProps = {
  setPageStep: React.Dispatch<React.SetStateAction<string>>;
  setTokenAndRedirect: (accessToken: string, refreshToken: string) => void;
  connexionMethods: UseFormReturn<{ nickNameOrEmail: string; password: string }, undefined>;
  isDoubleAuthEmail: boolean;
  setIsDoubleAuthEmail: (isDoubleAuthEmail: boolean) => void;
};

const TFAForm = ({ setPageStep, setTokenAndRedirect, connexionMethods, isDoubleAuthEmail, setIsDoubleAuthEmail }: TFAFormProps) => {
  const toastQueueRef = useContext(ToastQueueRefContext);
  const authService = new AuthService();

  const connexionStepTwoMethods = useForm<connexionStepTwoType>({
    resolver: yupResolver(connexionStepTwoSchema),
    mode: 'onSubmit',
    defaultValues: {
      code: '',
    },
  });

  const checkOneTimePassword = async ({ code }: connexionStepTwoType) => {
    const { accessToken, refreshToken } = await authService.checkOneTimePassword({
      nickNameOrEmail: connexionMethods.watch('nickNameOrEmail'),
      password: connexionMethods.watch('password'),
      oneTimePassword: Number(code),
      fromSkemeet: false,
    });

    setTokenAndRedirect(accessToken, refreshToken);
  };

  const login = async (resend: boolean) => {
    const loginReturn = await authService.login({
      nickNameOrEmail: connexionMethods.watch('nickNameOrEmail'),
      password: connexionMethods.watch('password'),
      fromSkemeet: false,
    });

    if (resend) {
      toastQueueRef?.current?.addToast({
        timer: 2000,
        description: 'Votre code de vérification a été renvoyé',
        type: 'SUCCESS',
      });
    }

    if (loginReturn.oneTimePasswordActivated) {
      setPageStep('connexionStepTwo');
      setIsDoubleAuthEmail(false);
    } else if (loginReturn.doubleAuthEmailActivated) {
      setPageStep('connexionStepTwo');
      setIsDoubleAuthEmail(true);
    } else {
      setTokenAndRedirect(loginReturn.accessToken, loginReturn.refreshToken);
    }
  };

  return (
    <>
      <FormHeader
        title={isDoubleAuthEmail ? 'Saisir le code de vérification ' : 'Entrer votre code de vérification de connexion envoyé par SMS'}
        subTitle={
          isDoubleAuthEmail
            ? 'Pour terminer votre connexion, veuillez saisir le code à 6 chiffres que nous vous avons envoyé par mail.'
            : 'Nous vous avons envoyé un code à 6 chiffres sur votre téléphone. Veuillez entrez le code ci-dessous pour vous connecter.'
        }
      />
      <FormProvider {...connexionStepTwoMethods}>
        <form onSubmit={connexionStepTwoMethods.handleSubmit(checkOneTimePassword)}>
          <Controller
            name='code'
            control={connexionStepTwoMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Code de vérification'
                  type='text'
                  placeholder='Ex : 12345678'
                  actionLabel={
                    <span
                      className='cursor-pointer font-medium text-green-secondary hover:font-semibold hover:underline'
                      onClick={() => login(true)}
                    >
                      Renvoyer le code
                    </span>
                  }
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          {isDoubleAuthEmail && (
            <div className='mt-5 flex items-center space-x-5'>
              <Icon.Danger color='#E48900' className='w-[30px]' />
              <span className='text-sm text-orange-primary'>
                L’envoi du code par SMS assure une sécurité plus élevée. Nous vous recommandons de choisir ce mode de connexion.
              </span>
            </div>
          )}
          <BasicButton label='Confirmer' fullWidth={true} isSubmitButton={true} />
          <div
            onClick={() => setPageStep('connexion')}
            className='mt-3 cursor-pointer text-center font-bold text-green-secondary hover:underline'
          >
            Retour à la page de connexion
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default TFAForm;
