import { FC, ReactElement, useEffect, useState } from 'react';
import { OutletProps, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Seo } from '../../components/common/Seo';
import * as Icon from '../../components/icons';
import { UserInfo } from '../../constants/types';
import UsersService from '../../services/users.service';

interface DashboardLayoutProps {
  Outlet: (props: OutletProps) => ReactElement | null;
}

const DashboardLayout: FC<DashboardLayoutProps> = ({ Outlet }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const usersService = new UsersService();
  const searchQueryParams = useSearchParams()[0];

  const [isTabContentHeightShort, setIsTabContentHeightShort] = useState<boolean>(false);
  const [user, setUser] = useState<UserInfo | null>(null);

  const activeTabCss =
    'text-blue-primary font-semibold min-md:bg-[#FFF6EB] min-md:border-r-4 min-md:border-r-orange-primary max-md:border-b-4 max-md:border-b-orange-primary';

  const menuItems = [
    {
      name: 'Authentification',
      link: '/dashboard/authentication',
    },
    {
      name: 'Mes applications',
      link: '/dashboard/apps',
    },
    {
      name: 'Connexions API',
      link: '/dashboard/api-connections',
    },
  ];

  useEffect(() => {
    (async () => {
      const queryParamsAccessToken = searchQueryParams.get('accessToken');
      const queryParamsRefreshToken = searchQueryParams.get('refreshToken');

      if (queryParamsAccessToken && queryParamsRefreshToken) {
        localStorage.setItem('accessToken', queryParamsAccessToken);
        localStorage.setItem('refreshToken', queryParamsRefreshToken);

        const projectLogoSrc = searchQueryParams.get('projectLogoSrc');
        const projectName = searchQueryParams.get('projectName');
        localStorage.setItem('projectLogoSrc', projectLogoSrc ? projectLogoSrc : '');
        localStorage.setItem('projectName', projectName ? projectName : '');

        navigate(`/dashboard/authentication`);
      } else {
        localStorage.removeItem('projectLogoSrc');
        localStorage.removeItem('projectName');
      }

      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        navigate(`/login`);
      }

      if (window.innerHeight > document.documentElement.offsetHeight) {
        setIsTabContentHeightShort(true);
      } else {
        setIsTabContentHeightShort(false);
      }

      const mailUpdatedToken = searchQueryParams.get('mailUpdatedToken');
      if (mailUpdatedToken) {
        navigate(`/dashboard/authentication`);
      }

      const user = await usersService.getUserData();
      setUser(user);
    })();
  }, []);

  return (
    <>
      <div>
        <div>
          <div className='my-5 flex items-center max-md:justify-center min-md:h-[30px] min-md:justify-between'>
            <span className='max-md:ml-0 min-md:ml-10'>
              <Icon.Skezi size={1.5} />
            </span>
            <span className='mr-6 flex max-md:hidden'>
              <span className='text-[0.75rem] text-gray-primary'>{user?.email}</span>
            </span>
            <span
              className='absolute right-1 mr-5 flex cursor-pointer items-center hover:font-medium hover:underline min-md:hidden'
              onClick={() => {
                navigate(`/logout`);
              }}
            >
              <Icon.Logout className='mr-3' />
            </span>
          </div>
        </div>
        <div className='flex max-md:flex-col'>
          <div className='relative max-md:flex min-md:w-72 min-md:pt-20'>
            {menuItems.map((item, i) => (
              <div
                key={i}
                className={`m-auto py-5 max-md:mb-3 max-md:w-[33.33%] max-md:text-center max-md:text-sm min-md:pl-5 ${location.pathname.includes(item.link) ? activeTabCss : 'cursor-pointer text-[#90ABBD]'} `}
                onClick={() => navigate(item.link)}
              >
                {item.name}
              </div>
            ))}
            <div
              className='absolute bottom-8 flex w-full cursor-pointer justify-center hover:font-medium hover:underline max-md:hidden'
              onClick={() => {
                navigate(`/logout`);
              }}
            >
              <Icon.Logout className='mr-3' />
              <span>Se déconnecter</span>
            </div>
          </div>
          <div
            className={`rounded-l-3xl bg-white-light p-7 ${isTabContentHeightShort && 'max-md:min-h-screen min-md:min-h-[calc(100vh-70px)]'} min-md:w-full`}
          >
            {location.pathname.includes('/dashboard/authentication/') && (
              <div className='mb-7 flex cursor-pointer max-md:hidden' onClick={() => navigate(`/dashboard/authentication`)}>
                <Icon.ArrowCircleLeft className='mr-3' /> <span className='text-[#7988A2]'>Authentification</span>
              </div>
            )}
            <Outlet context={{ user }} />
          </div>
        </div>
      </div>
      <Seo title={'Dashboard'} />
    </>
  );
};

export default DashboardLayout;
