import { FC } from 'react';
import { Modal } from '../../components/common/Modal';

type DisabledModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsDoubleAuthActivated: (isDoubleAuthActivated: boolean) => void;
  updateDoubleAuth: (isDoubleAuthActivated: boolean) => Promise<void>;
};

const DisabledModal: FC<DisabledModalProps> = ({ isOpen, setIsOpen, setIsDoubleAuthActivated, updateDoubleAuth }) => {
  const closeModal = () => setIsOpen(false);

  const disabledDoubleAuth = async () => {
    await updateDoubleAuth(false);
    setIsDoubleAuthActivated(false);
    closeModal();
  };

  return (
    <Modal
      modalOpen={isOpen}
      setModalOpenToFalse={() => setIsOpen(false)}
      modalWidth='w-[95%] max-w-[600px]'
      crossColor='#21577A'
      className='text-blue-primary'
    >
      <div className='-mt-2 flex flex-col items-center justify-center gap-8'>
        <h3 className='text-center text-xl font-semibold'>
          Désactiver l’authentification à<br /> double facteur par mail ?
        </h3>
        <span>Vous pourrez l’activer de nouveau après l’avoir désactivée.</span>
        <div className='flex items-center justify-center space-x-5'>
          <button
            type='button'
            className='rounded-full border-2 border-orange-primary bg-transparent px-5 py-2 font-semibold text-orange-primary'
            onClick={closeModal}
          >
            Annuler
          </button>
          <button type='button' className='rounded-full bg-red-500 px-5 py-2 font-semibold text-white' onClick={disabledDoubleAuth}>
            Désactiver
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DisabledModal;
