import { FC, SVGProps } from 'react';

export const Danger: FC<SVGProps<SVGSVGElement>> = ({ color = '#57CC99', ...props }) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M2 10.878c0-.363.093-.72.271-1.037l3.706-6.61a2.32 2.32 0 0 1 2.474-1.14v-.002a2.32 2.32 0 0 1 1.572 1.141l3.706 6.611A2.122 2.122 0 0 1 11.879 13H4.121A2.12 2.12 0 0 1 2 10.878Z'
        stroke={color}
        strokeWidth='1.1'
      />
      <path d='M8 6v2.667m0 2V11' stroke={color} strokeWidth='1.1' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
