import { FC } from 'react';
import { Modal } from '../../components/common/Modal';
import Info from '../../components/common/Info';
import { BasicButton } from '../../components/common/Button';
import { useNavigate } from 'react-router-dom';

type DoubleAuthSmsModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const DoubleAuthSmsModal: FC<DoubleAuthSmsModalProps> = ({ isOpen, setIsOpen }) => {
  const closeModal = () => setIsOpen(false);

  const navigate = useNavigate();

  return (
    <Modal
      modalOpen={isOpen}
      setModalOpenToFalse={() => setIsOpen(false)}
      modalWidth='w-[95%] max-w-[600px]'
      crossColor='#21577A'
      className='text-blue-primary'
    >
      <div className='-mt-2 flex flex-col items-center justify-center'>
        <h3 className='text-center text-xl font-semibold'>
          Activer l’authentification à <br /> double facteur par SMS ?
        </h3>
        <span className='my-5 block text-center text-gray-primary'>Vous pourrez toujours la désactiver plus tard.</span>
        <Info
          color='#F6A436'
          title='Pourquoi privilégier l’authentification à double facteur par SMS ?'
          content="L'activation de l'authentification à double facteur par SMS est une méthode efficace pour sécuriser vos comptes contre les accès non autorisés."
        />
        <div className='flex w-full space-x-5'>
          <BasicButton label='Non, annuler' template='white' className='flex-1' onClick={closeModal} />
          <BasicButton label='Oui, activer' className='flex-1' onClick={() => navigate('/dashboard/authentication/doubleAuthentication')} />
        </div>
      </div>
    </Modal>
  );
};

export default DoubleAuthSmsModal;
