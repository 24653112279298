import { apiRequest } from './fetcher';

interface LoginParams {
  nickNameOrEmail: string;
  password: string;
  fromSkemeet: boolean;
}

type LoginOTPParams = LoginParams & {
  oneTimePassword: number;
};

interface CreateUserFromSkemeetAndLoginParams {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  cellPhone: string;
}

interface LoginTokens {
  accessToken: string;
  refreshToken: string;
}

interface Login {
  success: boolean;
  oneTimePasswordActivated: boolean;
  doubleAuthEmailActivated: boolean;
  message: string;
  accessToken: string;
  refreshToken: string;
  changeSkemeetPassword?: boolean;
  firstname?: string;
  lastname?: string;
  cellPhone?: string;
  temporaryAccessToken?: string;
}

export default class AuthService {
  async createUserFromSkemeetAndLogin(data: CreateUserFromSkemeetAndLoginParams): Promise<LoginTokens> {
    return (
      await apiRequest.post(`/auth/create-user-from-skemeet-and-login`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    ).data;
  }

  async login(login: LoginParams): Promise<Login> {
    return (
      await apiRequest.post(`/auth/login`, login, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    ).data;
  }

  async checkOneTimePassword(loginOTP: LoginOTPParams): Promise<LoginTokens> {
    return (
      await apiRequest.post(`/auth/check-one-time-password`, loginOTP, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    ).data;
  }

  async getProSanteConnectLink(): Promise<{ authenticationUrl: string }> {
    return (
      await apiRequest.get(`/auth/pro-sante-connect/connexion`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    ).data;
  }

  async proSanteConnectCheckUserInfo(code: string): Promise<{
    success: boolean;
    message: string;
    hasEmail: boolean;
    hasEmailConfirmed: boolean;
    appConnectEmail: string | null;
    accessToken: string;
    refreshToken: string;
    askMergeAccount: boolean;
    appToken: string;
    idToken: string;
  }> {
    return (
      await apiRequest.post(
        `/auth/pro-sante-connect/checkUserInfo`,
        { code },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }

  async getFranceConnectLink(): Promise<{ authenticationUrl: string; state: string; nonce: string }> {
    return (
      await apiRequest.get(`/auth/france-connect/connexion`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    ).data;
  }

  async getFranceConnectLogoutLink(idToken: string): Promise<{ logoutUrl: string }> {
    return (
      await apiRequest.get(`/auth/france-connect/logout/${idToken}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    ).data;
  }

  async franceConnectCheckUserInfo(code: string): Promise<{
    success: boolean;
    message: string;
    hasEmail: boolean;
    hasEmailConfirmed: boolean;
    appConnectEmail: string | null;
    accessToken: string;
    refreshToken: string;
    askMergeAccount: boolean;
    appToken: string;
    idToken: string;
  }> {
    return (
      await apiRequest.post(
        `/auth/france-connect/checkUserInfo`,
        {
          code,
          nonce: localStorage.getItem('FranceConnectNonce'),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }

  async fcPscCreateAccount(appToken: string): Promise<{
    success: boolean;
    accessToken: string;
    refreshToken: string;
  }> {
    return (
      await apiRequest.post(
        `/auth/FC-PSC/createAccount`,
        { appToken },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }

  async fcPscMergeAccount(appToken: string): Promise<{
    success: boolean;
  }> {
    return (
      await apiRequest.post(
        `/auth/FC-PSC/mergeAccount`,
        { appToken },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }

  async resetNickname(email: string): Promise<void> {
    return (
      await apiRequest.post(
        `/auth/reset-nickname`,
        { email, locationUrl: window.location.href },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }

  async resetNicknameConfirm(token: string, nickName: string): Promise<void> {
    return (
      await apiRequest.post(
        `/auth/reset-nickname-confirm`,
        { token, nickName },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }

  async resetPassword(email: string): Promise<void> {
    return (
      await apiRequest.post(
        `/auth/reset-password`,
        { email, locationUrl: window.location.href },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }

  async resetPasswordConfirm(token: string, password: string): Promise<void> {
    return (
      await apiRequest.post(
        `/auth/reset-password-confirm`,
        { token, password },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }
}
