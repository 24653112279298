import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { BasicButton } from '../../../components/common/Button';
import { InputCheckbox, InputWord } from '../../../components/common/Input';
import { PasswordConstraint } from '../../../components/common/PasswordConstraint';
import FormHeader from '../../../components/forms/FormHeader';
import { subscriptionSchema, subscriptionType } from '../../../schema/SubscriptionSchema';
import AuthService from '../../../services/auth.service';
import UsersService from '../../../services/users.service';

type SubscriptionFormProps = {
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  setModaleCreatedAccountOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SubscriptionForm = ({ setHasError, setModaleCreatedAccountOpen }: SubscriptionFormProps) => {
  const navigate: NavigateFunction = useNavigate();
  const [passwordFocus, setPasswordFocus] = useState<boolean>(false);
  const authService = new AuthService();
  const usersService = new UsersService();
  const searchQueryParams = useSearchParams()[0];

  useEffect(() => {
    const redirectUrl = searchQueryParams.get('redirectUrl');
    // Je fais ainsi car avec le "navigate" du "react-router-dom V5" fait un decodeURIComponent automatiquement sur l'URL et ça pose problème pour le "redirectUrl" qui peut contenir des query params
    localStorage.setItem('redirectUrl', redirectUrl ? decodeURIComponent(redirectUrl) : '');
  }, []);

  const subscriptionMethods = useForm<subscriptionType>({
    resolver: yupResolver(subscriptionSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      nickname: '',
      password: '',
      confirmPassword: '',
      acceptCGU: false,
      subscribeNewsletter: false,
    },
  });

  const { watch } = subscriptionMethods;

  const getProSanteConnectAuthenticationUrl = async () => {
    try {
      const data = await authService.getProSanteConnectLink();
      document.location.href = data.authenticationUrl;
    } catch (error) {
      setHasError(true);
    }
  };

  const getFranceConnectAuthenticationUrl = async () => {
    try {
      const data = await authService.getFranceConnectLink();

      localStorage.setItem('FranceConnectState', data.state);
      localStorage.setItem('FranceConnectNonce', data.nonce);

      document.location.href = data.authenticationUrl;
    } catch (error) {
      setHasError(true);
    }
  };

  const createUser = async ({ email, nickname, password, subscribeNewsletter }: subscriptionType) => {
    await usersService.createUser({
      email,
      nickName: nickname,
      password,
      subscribeNewsletter,
    });

    setModaleCreatedAccountOpen(true);
  };

  return (
    <>
      <FormHeader
        topLabel='Déjà inscrit ?'
        actionTopLabel='Connectez-vous ici'
        actionTopOnClick={() => navigate(localStorage.getItem('redirectUrl') ? `/login?localStorageRedirectUrl=${true}` : `/login`)}
        title='Créer votre compte'
        proSanteConnect={true}
        franceConnect={import.meta.env.VITE_APP_URL.includes('.dev') || import.meta.env.VITE_APP_URL.includes('localhost')}
        getProSanteConnectAuthenticationUrl={getProSanteConnectAuthenticationUrl}
        getFranceConnectAuthenticationUrl={getFranceConnectAuthenticationUrl}
      />
      <FormProvider {...subscriptionMethods}>
        <form onSubmit={subscriptionMethods.handleSubmit(createUser)}>
          <Controller
            name='email'
            control={subscriptionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Email'
                  type='text'
                  mandatory={true}
                  placeholder='Email'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <Controller
            name='nickname'
            control={subscriptionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Identifiant'
                  type='text'
                  mandatory={true}
                  tooltip={true}
                  placeholder='Identifiant'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <Controller
            name='password'
            control={subscriptionMethods.control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Mot de passe'
                  type='password'
                  mandatory={true}
                  placeholder='Mot de passe'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                  onFocus={() => {
                    setPasswordFocus(true);
                  }}
                  onBlur={() => {
                    setPasswordFocus(false);
                    onBlur();
                  }}
                />
              </>
            )}
          />
          {passwordFocus && <PasswordConstraint password={watch('password')} />}
          <Controller
            name='confirmPassword'
            control={subscriptionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Confirmer le mot de passe'
                  type='password'
                  placeholder='Mot de passe'
                  mandatory={true}
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <Controller
            name='acceptCGU'
            control={subscriptionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputCheckbox
                  label={
                    <span className='text-xs'>
                      J’ai pris connaissance et accepte les{' '}
                      <span
                        onClick={() => window.open('/SKEZI_-_CGU_V1.pdf', '_blank')}
                        className='cursor-pointer font-medium text-green-secondary hover:font-semibold hover:underline'
                      >
                        Conditions Générales d’Utilisation
                      </span>{' '}
                      et la{' '}
                      <span
                        onClick={() => window.open('/SKEZI_-_Politique_de_Confidentialite_V2.0.pdf', '_blank')}
                        className='cursor-pointer font-medium text-green-secondary hover:font-semibold hover:underline'
                      >
                        Politique de confidentialité
                      </span>
                    </span>
                  }
                  checked={value}
                  actionOnClick={() => onChange(!value)}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <Controller
            name='subscribeNewsletter'
            control={subscriptionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputCheckbox
                  label={<span className='text-xs'>Je souhaite être informé des futures évolutions et actualités des solutions</span>}
                  checked={value}
                  actionOnClick={() => onChange(!value)}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <BasicButton label="S'inscrire" fullWidth={true} isSubmitButton={true} />
        </form>
      </FormProvider>
    </>
  );
};

export default SubscriptionForm;
