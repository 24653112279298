import { FC } from 'react';
import { Modal } from '../../components/common/Modal';
import Info from '../../components/common/Info';

type ConfirmModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsDoubleAuthActivated: (isDoubleAuthActivated: boolean) => void;
  updateDoubleAuth: (isDoubleAuthActivated: boolean) => Promise<void>;
};

const ConfirmModal: FC<ConfirmModalProps> = ({ isOpen, setIsOpen, setIsDoubleAuthActivated, updateDoubleAuth }) => {
  const closeModal = () => setIsOpen(false);

  const activatedDoubleAuth = async () => {
    await updateDoubleAuth(true);
    setIsDoubleAuthActivated(true);
    closeModal();
  };

  return (
    <Modal
      modalOpen={isOpen}
      setModalOpenToFalse={() => setIsOpen(false)}
      modalWidth='w-[95%] max-w-[600px]'
      crossColor='#21577A'
      className='text-blue-primary'
    >
      <div className='-mt-2 flex flex-col items-center justify-center gap-8'>
        <h3 className='text-center text-xl font-semibold'>
          Activer l’authentification à <br /> double facteur par mail ?
        </h3>
        <Info
          color='#F6A436'
          title='Cette méthode est peu recommandée :'
          content="Optez plutôt pour l'authentification à double facteur par SMS afin de sécuriser davantage votre connexion."
        />
        <span>Vous pourrez toujours la désactiver plus tard.</span>
        <div className='flex items-center justify-center space-x-5'>
          <button
            type='button'
            className='rounded-full border-2 border-orange-primary bg-transparent px-5 py-2 font-semibold text-orange-primary'
            onClick={closeModal}
          >
            Annuler
          </button>
          <button type='button' className='rounded-full bg-orange-primary px-5 py-2 font-semibold text-white' onClick={activatedDoubleAuth}>
            Activer
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
