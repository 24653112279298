import { UserCreate, UserInfo } from '../constants/types';
import { apiRequest } from './fetcher';
export default class UsersService {
  async createUser(user: UserCreate): Promise<void> {
    return (
      await apiRequest.post(
        `/users/create`,
        {
          email: user.email,
          nickName: user.nickName,
          password: user.password,
          subscribeNewsletter: user.subscribeNewsletter,
          locationUrl: window.location.href,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }

  async getUserData(): Promise<UserInfo> {
    return (
      await apiRequest.get('/users/me', {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    ).data;
  }

  async sendEmailValidation(email: string, appToken: string): Promise<void> {
    return (
      await apiRequest.post(
        `/users/send-email-validation`,
        {
          email,
          appToken,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }

  async sendEmailMergeValidation(email: string, appToken: string): Promise<void> {
    return (
      await apiRequest.post(
        `/users/send-email-merge-validation`,
        {
          email,
          appToken,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }

  async validateAccount(updateEmailToken: string): Promise<void> {
    return (
      await apiRequest.post(
        `/users/validate-account`,
        { updateEmailToken },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }

  async updateEmail(data: { email: string }) {
    return (
      await apiRequest.post('/users/update-email', data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    ).data;
  }

  async updateNickname(data: { nickName: string }) {
    return (
      await apiRequest.post('/users/update-nickname', data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    ).data;
  }

  async updatePassword(data: { oldPassword: string; newPassword: string }) {
    return (
      await apiRequest.patch(`/users/password`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    ).data;
  }

  async updateDoubleAuthentication(oneTimePasswordActivated: boolean, cellphone: string): Promise<void> {
    return (
      await apiRequest.post(
        `/users/update-double-authentication`,
        {
          oneTimePasswordActivated,
          cellphone,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }

  async updateDoubleAuthEmail(isDoubleAuthEmailActivated: boolean): Promise<void> {
    return (
      await apiRequest.put(
        `/users/update-double-authentication-email`,
        { doubleAuthEmailActivated: isDoubleAuthEmailActivated },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }

  async sendSupportMessage(name: string, email: string, content: string): Promise<void> {
    return (
      await apiRequest.post(
        `/users/send-support-message`,
        {
          name,
          email,
          content,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        },
      )
    ).data;
  }
}
