import { useState } from 'react';
import * as Icon from '../../components/icons';
import DoubleAuthSmsModal from './DoubleAuthSmsModal';
import { useLocation } from 'react-router-dom';

const DashboardAppsComponent = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(location.state?.isDoubleAuthSmsModalOpen ?? false);

  return (
    <>
      <div className='flex'>
        <div
          className='mr-8 flex h-32 w-60 cursor-pointer rounded-lg border border-gray-tertiary bg-white max-md:relative'
          onClick={() =>
            (window.location.href = `${import.meta.env.VITE_SKEZIA_FRONT_URL}/login?accessToken=${localStorage.getItem(
              'accessToken',
            )}&refreshToken=${localStorage.getItem('refreshToken')}`)
          }
        >
          <Icon.Skezia className='m-auto' />
          <div className='absolute bottom-3 left-[1.1rem] border-b border-blue-primary text-blue-primary min-md:hidden'>
            Accéder à la solution
          </div>
        </div>
        <div
          className='flex h-32 w-60 cursor-pointer rounded-lg border border-gray-tertiary bg-white max-md:relative'
          onClick={() =>
            (window.location.href = `${import.meta.env.VITE_SKEMEET_FRONT_URL}/p/patients?accessToken=${localStorage.getItem(
              'accessToken',
            )}&refreshToken=${localStorage.getItem('refreshToken')}`)
          }
        >
          <Icon.Skemeet className='m-auto' />
          <div className='absolute bottom-3 left-[1.1rem] border-b border-blue-primary text-blue-primary min-md:hidden'>
            Accéder à la solution
          </div>
        </div>
      </div>
      <DoubleAuthSmsModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default DashboardAppsComponent;
