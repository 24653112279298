import React, { FC, SVGProps } from 'react';

export const ProSanteConnect: FC<SVGProps<SVGSVGElement>> = ({ color = '#000091', ...props }) => {
  return (
    <svg id='Calque_1' data-name='Calque 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 553.08 141.25' {...props}>
      <defs>
        <style>
          {`.cls-1,.cls-5{fill:${color};}.cls-2,.cls-6{fill:#fff;}.cls-3{fill:#6f7072;}.cls-3,.cls-4,.cls-5,.cls-6{stroke:#fff;stroke-miterlimit:10;}.cls-3,.cls-4,.cls-5{stroke-width:4.45px;}.cls-4{fill:#e1000f;}.cls-6{stroke-width:1.78px;}`}
        </style>
      </defs>
      <path
        className='cls-1'
        d='M540.38,141.28H70.68A70.62,70.62,0,0,1,.06,70.66h0A70.61,70.61,0,0,1,70.68.05h469.7a12.67,12.67,0,0,1,12.67,12.67V128.61A12.67,12.67,0,0,1,540.38,141.28Z'
      />
      <path
        className='cls-2'
        d='M140,38.28l-2.31,1.23c-.36-.73-.82-1.1-1.35-1.1a.92.92,0,0,0-.66.25.83.83,0,0,0-.28.65q0,.7,1.62,1.38a10,10,0,0,1,3,1.76,3.05,3.05,0,0,1,.77,2.18,3.8,3.8,0,0,1-1.3,2.93,4.62,4.62,0,0,1-7.34-1.86l2.38-1.1a5,5,0,0,0,.76,1.1,1.72,1.72,0,0,0,1.22.48c.94,0,1.42-.43,1.42-1.3,0-.49-.37-1-1.1-1.39l-.84-.41-.86-.41a5.4,5.4,0,0,1-1.72-1.19,3,3,0,0,1-.63-1.95,3.43,3.43,0,0,1,1.08-2.61,3.79,3.79,0,0,1,2.69-1A3.65,3.65,0,0,1,140,38.28Z'
      />
      <path className='cls-2' d='M146,28.3l2.49,1-3.55,7.11L143,35.6Z' />
      <path
        className='cls-2'
        d='M151.23,31.18a1.69,1.69,0,0,1,.54-1.26,1.72,1.72,0,0,1,1.28-.54,1.76,1.76,0,0,1,1.29.54,1.68,1.68,0,0,1,.53,1.28,1.75,1.75,0,0,1-.53,1.29,1.7,1.7,0,0,1-1.28.53,1.72,1.72,0,0,1-1.29-.53A1.77,1.77,0,0,1,151.23,31.18Zm3.22,5V48.33h-2.8V36.22Z'
      />
      <path
        className='cls-2'
        d='M166.92,27.33h2.81v21h-2.81V47.06a5.21,5.21,0,0,1-3.74,1.62,5.34,5.34,0,0,1-4.13-1.82,6.77,6.77,0,0,1-1.63-4.62,6.56,6.56,0,0,1,1.63-4.53,5.23,5.23,0,0,1,7.87-.09Zm-6.64,14.91a4.21,4.21,0,0,0,.93,2.83,3.07,3.07,0,0,0,2.42,1.11,3.23,3.23,0,0,0,2.51-1.07,4.59,4.59,0,0,0,0-5.62,3.18,3.18,0,0,0-2.49-1.08,3.09,3.09,0,0,0-2.41,1.09A4,4,0,0,0,160.28,42.24Z'
      />
      <path
        className='cls-2'
        d='M184.29,42.93h-8.68a3.72,3.72,0,0,0,1,2.38,3,3,0,0,0,2.2.87,2.88,2.88,0,0,0,1.73-.5A7.05,7.05,0,0,0,182,43.84l2.36,1.32a8.83,8.83,0,0,1-1.16,1.59,6,6,0,0,1-1.3,1.1,5.74,5.74,0,0,1-1.51.63,7.38,7.38,0,0,1-1.75.2,5.69,5.69,0,0,1-4.35-1.74,6.55,6.55,0,0,1-1.64-4.65,6.69,6.69,0,0,1,1.59-4.65,6,6,0,0,1,8.48,0,6.67,6.67,0,0,1,1.54,4.68Zm-2.87-2.29a2.68,2.68,0,0,0-2.83-2.23,3,3,0,0,0-1,.15,3.11,3.11,0,0,0-.82.45,2.78,2.78,0,0,0-.62.7,3.23,3.23,0,0,0-.4.93Z'
      />
      <path
        className='cls-2'
        d='M187.35,36.22h2.81v1.12a4.57,4.57,0,0,1,3.3-1.46,4.21,4.21,0,0,1,3.3,1.33,5.47,5.47,0,0,1,1,3.7v7.42H195V41.57a4.45,4.45,0,0,0-.5-2.47,2,2,0,0,0-1.77-.69,2.14,2.14,0,0,0-2,.92,6.31,6.31,0,0,0-.57,3.16v5.84h-2.81Z'
      />
      <path className='cls-2' d='M204.19,38.83v9.5h-2.8v-9.5H200.2V36.22h1.19V31.78h2.8v4.44h2.18v2.61Z' />
      <path
        className='cls-2'
        d='M207.85,31.18a1.69,1.69,0,0,1,.53-1.26,1.8,1.8,0,0,1,2.57,0,1.69,1.69,0,0,1,.54,1.28,1.76,1.76,0,0,1-.54,1.29,1.68,1.68,0,0,1-1.28.53,1.81,1.81,0,0,1-1.82-1.84Zm3.22,5V48.33h-2.8V36.22Z'
      />
      <path
        className='cls-2'
        d='M217.51,38.83v9.5H214.7v-9.5h-1V36.22h1V31.54a5.08,5.08,0,0,1,.79-3.23A3.9,3.9,0,0,1,218.67,27a5.48,5.48,0,0,1,1.88.44v2.86l-.28-.15a3.51,3.51,0,0,0-1.5-.46,1,1,0,0,0-1,.53,5.06,5.06,0,0,0-.26,2v4h3v2.61Z'
      />
      <path
        className='cls-2'
        d='M222.28,31.18a1.72,1.72,0,0,1,.53-1.26,1.81,1.81,0,0,1,2.58,0,1.72,1.72,0,0,1,.53,1.28,1.79,1.79,0,0,1-.53,1.29,1.71,1.71,0,0,1-1.28.53,1.76,1.76,0,0,1-1.3-.53A1.8,1.8,0,0,1,222.28,31.18Zm3.22,5V48.33h-2.8V36.22Z'
      />
      <path
        className='cls-2'
        d='M240.07,42.93h-8.68a3.72,3.72,0,0,0,1,2.38,3,3,0,0,0,2.2.87,2.88,2.88,0,0,0,1.73-.5,7.05,7.05,0,0,0,1.53-1.84l2.36,1.32A9.31,9.31,0,0,1,239,46.75a6.33,6.33,0,0,1-1.31,1.1,5.68,5.68,0,0,1-1.5.63,7.53,7.53,0,0,1-1.76.2,5.72,5.72,0,0,1-4.35-1.74,6.55,6.55,0,0,1-1.64-4.65,6.69,6.69,0,0,1,1.59-4.65,5.49,5.49,0,0,1,4.25-1.76,5.43,5.43,0,0,1,4.23,1.71,6.67,6.67,0,0,1,1.54,4.68Zm-2.87-2.29a2.67,2.67,0,0,0-2.82-2.23,3,3,0,0,0-1,.15,3.11,3.11,0,0,0-.82.45,2.78,2.78,0,0,0-.62.7,3.23,3.23,0,0,0-.4.93Z'
      />
      <path
        className='cls-2'
        d='M243.13,36.22h2.8V37.3a5.25,5.25,0,0,1,1.36-1.11,3.25,3.25,0,0,1,1.44-.31,4.56,4.56,0,0,1,2.32.72l-1.28,2.57a2.63,2.63,0,0,0-1.56-.58c-1.52,0-2.28,1.16-2.28,3.46v6.28h-2.8Z'
      />
      <path
        className='cls-2'
        d='M269.43,36.22h2.81V48.33h-2.81V47.06a5.36,5.36,0,0,1-7.87-.2,6.77,6.77,0,0,1-1.62-4.62,6.56,6.56,0,0,1,1.62-4.54,5.27,5.27,0,0,1,4.08-1.82,5.15,5.15,0,0,1,3.79,1.74Zm-6.63,6a4.21,4.21,0,0,0,.93,2.83,3,3,0,0,0,2.41,1.11,3.21,3.21,0,0,0,2.51-1.07,4.59,4.59,0,0,0,0-5.62,3.18,3.18,0,0,0-2.49-1.08,3.09,3.09,0,0,0-2.41,1.09A4,4,0,0,0,262.8,42.24Z'
      />
      <path className='cls-2' d='M277.5,36.22l3.13,6.8,3.13-6.8h3.14l-6.29,12.94-6.24-12.94Z' />
      <path
        className='cls-2'
        d='M299.38,42.93H290.7a3.72,3.72,0,0,0,1,2.38,3,3,0,0,0,2.2.87,2.86,2.86,0,0,0,1.73-.5,7.05,7.05,0,0,0,1.53-1.84l2.36,1.32a9.31,9.31,0,0,1-1.15,1.59,6.08,6.08,0,0,1-1.31,1.1,5.54,5.54,0,0,1-1.5.63,7.44,7.44,0,0,1-1.75.2,5.72,5.72,0,0,1-4.36-1.74,6.55,6.55,0,0,1-1.64-4.65,6.69,6.69,0,0,1,1.59-4.65,5.5,5.5,0,0,1,4.26-1.76,5.41,5.41,0,0,1,4.22,1.71,6.67,6.67,0,0,1,1.54,4.68Zm-2.87-2.29a2.67,2.67,0,0,0-2.82-2.23,3,3,0,0,0-1,.15,2.91,2.91,0,0,0-.81.45,2.64,2.64,0,0,0-.63.7,3.23,3.23,0,0,0-.4.93Z'
      />
      <path
        className='cls-2'
        d='M311.86,36.74v3.72a6.79,6.79,0,0,0-1.71-1.61,3.43,3.43,0,0,0-1.76-.44,3.48,3.48,0,0,0-2.62,1.1,4.31,4.31,0,0,0,0,5.57,3.41,3.41,0,0,0,2.6,1.1,3.56,3.56,0,0,0,1.78-.44,7,7,0,0,0,1.75-1.64v3.69a7.31,7.31,0,0,1-3.4.89,6.51,6.51,0,0,1-4.7-1.82,6.06,6.06,0,0,1-1.89-4.54,6.15,6.15,0,0,1,1.91-4.57,6.47,6.47,0,0,1,4.7-1.87A7,7,0,0,1,311.86,36.74Z'
      />
      <path
        className='cls-2'
        d='M71.28,27.88a2.55,2.55,0,0,1,1.29.35l34.85,20a2.58,2.58,0,0,1,1.29,2.23l.09,40.19a2.58,2.58,0,0,1-1.29,2.23L72.75,113.07a2.55,2.55,0,0,1-1.29.35,2.64,2.64,0,0,1-1.29-.34l-34.84-20A2.58,2.58,0,0,1,34,90.83l-.09-40.19a2.59,2.59,0,0,1,1.29-2.24L70,28.23a2.55,2.55,0,0,1,1.29-.35m0-5.08a7.63,7.63,0,0,0-3.84,1L32.68,44a7.67,7.67,0,0,0-3.82,6.65L29,90.84a7.66,7.66,0,0,0,3.84,6.63l34.85,20a7.74,7.74,0,0,0,3.82,1,7.63,7.63,0,0,0,3.84-1L110.07,97.3a7.68,7.68,0,0,0,3.81-6.65l-.08-40.19A7.68,7.68,0,0,0,110,43.84l-34.85-20a7.65,7.65,0,0,0-3.82-1Z'
      />
      <circle className='cls-3' cx={111.51} cy={70.03} r={7.05} />
      <circle className='cls-4' cx={52.02} cy={36.17} r={7.05} />
      <circle className='cls-5' cx={49.09} cy={104.34} r={7.05} />
      <path
        className='cls-6'
        d='M84.94,67.31l-26,.41a1.83,1.83,0,0,1-1.86-1.81l-.19-8.13A13.87,13.87,0,0,1,70.51,43.71l2,0A13.86,13.86,0,0,1,86.56,57.31l.18,8.14A1.83,1.83,0,0,1,84.94,67.31ZM60.71,64l22.34-.35-.16-6.3a10.19,10.19,0,0,0-10.34-10l-2,0a10.19,10.19,0,0,0-10,10.34Z'
      />
      <path
        className='cls-6'
        d='M85.31,91.06l-26,.41a1.85,1.85,0,0,1-1.86-1.81l-.37-23.75a1.83,1.83,0,0,1,1.8-1.86l26-.41a1.84,1.84,0,0,1,1.86,1.81l.38,23.74A1.84,1.84,0,0,1,85.31,91.06ZM61.08,87.77l22.34-.35-.31-20.08-22.35.35Z'
      />
      <path
        className='cls-6'
        d='M72.17,82.22a1.85,1.85,0,0,1-1.87-1.81l-.09-5.65a1.84,1.84,0,1,1,3.67-.06L74,80.35A1.83,1.83,0,0,1,72.17,82.22Z'
      />
      <path
        className='cls-2'
        d='M140,84.73h-7.48V56h11.91q4.84,0,7.43,2.52a9.49,9.49,0,0,1,2.57,7.1,9.51,9.51,0,0,1-2.57,7.1q-2.58,2.52-7.43,2.52H140Zm0-15.57h2.48q4.13,0,4.13-3.59T142.49,62H140Z'
      />
      <path
        className='cls-2'
        d='M180.44,84.73h-9.32L164,73.66V84.73H156.5V56h11.64a11.2,11.2,0,0,1,4.2.71,8.08,8.08,0,0,1,3,1.92,8.23,8.23,0,0,1,1.76,2.83,9.93,9.93,0,0,1,.59,3.43,8.49,8.49,0,0,1-1.58,5.33,8,8,0,0,1-4.68,2.77ZM164,68.81h1.42a5.45,5.45,0,0,0,3.39-.91,3.51,3.51,0,0,0,0-5.27,5.45,5.45,0,0,0-3.39-.92H164Z'
      />
      <path
        className='cls-2'
        d='M179.82,70.34a15,15,0,0,1,1.19-6,14.77,14.77,0,0,1,8.3-8.06,18.35,18.35,0,0,1,12.84,0,15.13,15.13,0,0,1,5.06,3.23,14.57,14.57,0,0,1,3.3,4.83,15.57,15.57,0,0,1,0,11.94,14.57,14.57,0,0,1-3.3,4.83,15.13,15.13,0,0,1-5.06,3.23,18.35,18.35,0,0,1-12.84,0,14.93,14.93,0,0,1-9.49-14Zm7.83,0a7.75,7.75,0,0,0,.65,3.17,7.88,7.88,0,0,0,4.33,4.18A8.34,8.34,0,0,0,201.45,76a7.87,7.87,0,0,0,1.77-2.52,8.05,8.05,0,0,0,0-6.34,7.76,7.76,0,0,0-1.77-2.52A8.41,8.41,0,0,0,192.63,63a8.11,8.11,0,0,0-2.58,1.66,8,8,0,0,0-1.75,2.52A7.71,7.71,0,0,0,187.65,70.34Z'
      />
      <path
        className='cls-2'
        d='M235.67,63.32a8.85,8.85,0,0,0-2.45-1.47,6.42,6.42,0,0,0-2.36-.48,3.83,3.83,0,0,0-2.37.69,2.12,2.12,0,0,0-.91,1.79,1.8,1.8,0,0,0,.45,1.26,4,4,0,0,0,1.21.86,10.19,10.19,0,0,0,1.68.63c.62.18,1.24.37,1.85.57a11.52,11.52,0,0,1,5.36,3.27,8,8,0,0,1,1.7,5.32,10.31,10.31,0,0,1-.75,4,8.35,8.35,0,0,1-2.17,3.07,10.35,10.35,0,0,1-3.51,2,14.68,14.68,0,0,1-4.72.7,17.32,17.32,0,0,1-10.11-3.24l3.2-6a13,13,0,0,0,3.32,2.21,8.07,8.07,0,0,0,3.25.72,3.88,3.88,0,0,0,2.73-.83,2.56,2.56,0,0,0,.9-1.91,2.62,2.62,0,0,0-.23-1.13,2.51,2.51,0,0,0-.77-.88,6.34,6.34,0,0,0-1.39-.74c-.57-.23-1.27-.48-2.08-.76-1-.31-1.92-.65-2.84-1a8.85,8.85,0,0,1-2.49-1.47,6.8,6.8,0,0,1-1.75-2.31,8.05,8.05,0,0,1-.67-3.53,10.22,10.22,0,0,1,.71-3.87,8.64,8.64,0,0,1,2-3,9,9,0,0,1,3.13-1.93,12.06,12.06,0,0,1,4.14-.68,18.32,18.32,0,0,1,4.47.59,19.36,19.36,0,0,1,4.47,1.74Z'
      />
      <path className='cls-2' d='M259.72,79.73H249l-1.72,5h-8l11-28.78h8.17l11,28.78h-8Zm-2-5.69-3.36-9.58L251,74Z' />
      <path className='cls-2' d='M270.78,84.73V56h7.49l13.81,17.6V56h7.45V84.73h-7.45l-13.81-17.6v17.6Z' />
      <path className='cls-2' d='M314.57,62.29V84.73h-7.49V62.29h-6.14V56h19.77v6.34Z' />
      <path
        className='cls-2'
        d='M338.5,62.29h-8.89V67.1H338v6.33h-8.39v5h8.89v6.34H322.12V56H338.5ZM336.14,57l-8.35,4-3.18-3.24,8.87-4.67Z'
      />
      <path
        className='cls-2'
        d='M368,65.57a8,8,0,0,0-6.41-3.17,7.77,7.77,0,0,0-3.11.61,7.47,7.47,0,0,0-2.46,1.66,7.68,7.68,0,0,0-1.63,2.5,8.3,8.3,0,0,0-.59,3.13,8.4,8.4,0,0,0,.59,3.17,7.69,7.69,0,0,0,4.11,4.2,7.55,7.55,0,0,0,3,.61,8.68,8.68,0,0,0,6.45-3v8.85l-.76.27a24.34,24.34,0,0,1-3.21.9,15.56,15.56,0,0,1-2.94.28,14.81,14.81,0,0,1-5.7-1.12,15,15,0,0,1-4.81-3.17,15.71,15.71,0,0,1-3.34-4.85,15.56,15.56,0,0,1,0-12.22,15,15,0,0,1,8.15-7.9,15,15,0,0,1,5.76-1.12,15.9,15.9,0,0,1,3.38.36A20.13,20.13,0,0,1,368,56.64Z'
      />
      <path
        className='cls-2'
        d='M370,70.34a15,15,0,0,1,1.18-6,14.82,14.82,0,0,1,3.28-4.83,15.05,15.05,0,0,1,5-3.23,18.37,18.37,0,0,1,12.85,0,15.23,15.23,0,0,1,5.06,3.23,14.72,14.72,0,0,1,3.3,4.83,15.69,15.69,0,0,1,0,11.94,14.72,14.72,0,0,1-3.3,4.83,15.23,15.23,0,0,1-5.06,3.23,18.37,18.37,0,0,1-12.85,0,15.05,15.05,0,0,1-5-3.23,14.82,14.82,0,0,1-3.28-4.83A15,15,0,0,1,370,70.34Zm7.82,0a7.75,7.75,0,0,0,.65,3.17,8,8,0,0,0,4.33,4.18,8.52,8.52,0,0,0,6.23,0A8.26,8.26,0,0,0,391.61,76a8.06,8.06,0,0,0,1.78-2.52,8.05,8.05,0,0,0,0-6.34,7.94,7.94,0,0,0-1.78-2.52A8.26,8.26,0,0,0,389,63a8.52,8.52,0,0,0-6.23,0,8.06,8.06,0,0,0-2.57,1.66,7.89,7.89,0,0,0-1.76,2.52A7.71,7.71,0,0,0,377.81,70.34Z'
      />
      <path className='cls-2' d='M404.69,84.73V56h7.48L426,73.55V56h7.45V84.73H426l-13.81-17.6v17.6Z' />
      <path className='cls-2' d='M437.55,84.73V56H445l13.82,17.6V56h7.44V84.73h-7.44L445,67.13v17.6Z' />
      <path className='cls-2' d='M486.79,62.29H477.9V67.1h8.39v6.33H477.9v5h8.89v6.34H470.41V56h16.38Z' />
      <path
        className='cls-2'
        d='M511,65.57a8,8,0,0,0-6.41-3.17,7.77,7.77,0,0,0-3.11.61A7.62,7.62,0,0,0,499,64.67a7.82,7.82,0,0,0-1.62,2.5,8.3,8.3,0,0,0-.59,3.13,8.4,8.4,0,0,0,.59,3.17,7.61,7.61,0,0,0,4.11,4.2,7.52,7.52,0,0,0,3.05.61,8.68,8.68,0,0,0,6.45-3v8.85l-.76.27a24.77,24.77,0,0,1-3.21.9,15.56,15.56,0,0,1-2.94.28,14.86,14.86,0,0,1-5.71-1.12,15.13,15.13,0,0,1-4.81-3.17,15.88,15.88,0,0,1-3.34-4.85,15.62,15.62,0,0,1,0-12.22,15.18,15.18,0,0,1,13.91-9,16,16,0,0,1,3.38.36A20.41,20.41,0,0,1,511,56.64Z'
      />
      <path className='cls-2' d='M526.26,62.29V84.73h-7.48V62.29h-6.15V56H532.4v6.34Z' />
      <path
        className='cls-2'
        d='M206,95.23v8.48a4.7,4.7,0,0,0,.59,2.65,3.18,3.18,0,0,0,5,0,4.55,4.55,0,0,0,.6-2.65V95.23h2.12v9.07a5.08,5.08,0,0,1-1.38,3.66,5.36,5.36,0,0,1-7.66,0,5.08,5.08,0,0,1-1.38-3.66V95.23Z'
      />
      <path
        className='cls-2'
        d='M216.44,100.43h2.06v.82a3.39,3.39,0,0,1,2.43-1.07,3,3,0,0,1,2.41,1,4,4,0,0,1,.75,2.71v5.44H222v-5a3.21,3.21,0,0,0-.37-1.82,1.45,1.45,0,0,0-1.29-.51,1.58,1.58,0,0,0-1.45.68,4.62,4.62,0,0,0-.42,2.32v4.28h-2.06Z'
      />
      <path
        className='cls-2'
        d='M235.74,101.94l-1.7.91c-.26-.55-.59-.82-1-.82a.69.69,0,0,0-.48.19.61.61,0,0,0-.2.48c0,.34.39.68,1.18,1a7.26,7.26,0,0,1,2.2,1.29,2.22,2.22,0,0,1,.57,1.6,2.82,2.82,0,0,1-.95,2.15,3.28,3.28,0,0,1-2.23.82,3.23,3.23,0,0,1-3.16-2.18l1.75-.81a4.36,4.36,0,0,0,.55.81,1.28,1.28,0,0,0,.9.34c.69,0,1-.31,1-.94,0-.37-.27-.71-.81-1l-.62-.3-.62-.3a3.78,3.78,0,0,1-1.26-.87,2.17,2.17,0,0,1-.47-1.44,2.52,2.52,0,0,1,.8-1.91,2.75,2.75,0,0,1,2-.76A2.69,2.69,0,0,1,235.74,101.94Z'
      />
      <path
        className='cls-2'
        d='M245.32,105.35H239a2.71,2.71,0,0,0,.71,1.75,2.19,2.19,0,0,0,1.61.63,2.12,2.12,0,0,0,1.27-.36,5.06,5.06,0,0,0,1.12-1.35l1.74,1a7.36,7.36,0,0,1-.85,1.17,4.75,4.75,0,0,1-1,.8,4,4,0,0,1-1.1.46,5.31,5.31,0,0,1-1.29.15,4.19,4.19,0,0,1-3.19-1.28,4.8,4.8,0,0,1-1.21-3.41,4.9,4.9,0,0,1,1.17-3.41,4,4,0,0,1,3.12-1.29,4,4,0,0,1,3.1,1.25,4.89,4.89,0,0,1,1.13,3.44Zm-2.11-1.67a2.12,2.12,0,0,0-2.77-1.53,1.77,1.77,0,0,0-.6.33,1.87,1.87,0,0,0-.46.51,2.4,2.4,0,0,0-.3.69Z'
      />
      <path
        className='cls-2'
        d='M246.62,100.43h2.06v.79a4,4,0,0,1,1-.81,2.33,2.33,0,0,1,1.06-.23,3.36,3.36,0,0,1,1.7.53l-.94,1.88a2,2,0,0,0-1.14-.42c-1.12,0-1.68.85-1.68,2.54v4.6h-2.06Z'
      />
      <path className='cls-2' d='M254.58,100.43l2.3,5,2.29-5h2.31l-4.62,9.49-4.58-9.49Z' />
      <path
        className='cls-2'
        d='M261.62,96.73a1.34,1.34,0,0,1,2.28-.93,1.28,1.28,0,0,1,.39.94,1.3,1.3,0,0,1-.39,1,1.26,1.26,0,0,1-.94.39,1.33,1.33,0,0,1-1.34-1.35Zm2.37,3.7v8.88h-2.06v-8.88Z'
      />
      <path
        className='cls-2'
        d='M272.56,100.81v2.73a4.87,4.87,0,0,0-1.25-1.18A2.48,2.48,0,0,0,270,102a2.54,2.54,0,0,0-1.93.82,2.83,2.83,0,0,0-.76,2,2.87,2.87,0,0,0,.74,2.05,2.46,2.46,0,0,0,1.9.8,2.64,2.64,0,0,0,1.31-.31,5.24,5.24,0,0,0,1.28-1.21v2.71a5.38,5.38,0,0,1-2.49.65,4.8,4.8,0,0,1-3.45-1.33,4.45,4.45,0,0,1-1.39-3.33,4.49,4.49,0,0,1,1.41-3.36,4.74,4.74,0,0,1,3.44-1.37A5.11,5.11,0,0,1,272.56,100.81Z'
      />
      <path
        className='cls-2'
        d='M281.71,105.35h-6.37a2.76,2.76,0,0,0,.71,1.75,2.19,2.19,0,0,0,1.62.63,2.14,2.14,0,0,0,1.27-.36,5.25,5.25,0,0,0,1.12-1.35l1.73,1a7.36,7.36,0,0,1-.85,1.17,4.44,4.44,0,0,1-.95.8,4,4,0,0,1-1.11.46,5.17,5.17,0,0,1-1.28.15,4.21,4.21,0,0,1-3.2-1.28,4.8,4.8,0,0,1-1.2-3.41,4.9,4.9,0,0,1,1.17-3.41,4.44,4.44,0,0,1,6.22,0,4.93,4.93,0,0,1,1.13,3.44Zm-2.11-1.67a2,2,0,0,0-2.07-1.65,2,2,0,0,0-.7.12,1.77,1.77,0,0,0-.6.33,2,2,0,0,0-.46.51,2.39,2.39,0,0,0-.29.69Z'
      />
      <path
        className='cls-2'
        d='M294.4,93.91h2.06v15.4H294.4v-.93a3.83,3.83,0,0,1-2.75,1.19,4,4,0,0,1-3-1.33,5,5,0,0,1-1.19-3.4,4.83,4.83,0,0,1,1.19-3.32,3.85,3.85,0,0,1,3-1.34,3.79,3.79,0,0,1,2.79,1.28Zm-4.87,10.93a3.12,3.12,0,0,0,.68,2.08,2.25,2.25,0,0,0,1.77.81,2.34,2.34,0,0,0,1.84-.78,3.34,3.34,0,0,0,0-4.12A2.3,2.3,0,0,0,292,102a2.25,2.25,0,0,0-1.77.81A2.94,2.94,0,0,0,289.53,104.84Z'
      />
      <path
        className='cls-2'
        d='M300.17,100.43v5.1c0,1.47.58,2.2,1.74,2.2s1.74-.73,1.74-2.2v-5.1h2.06v5.14a5.68,5.68,0,0,1-.27,1.85,3.15,3.15,0,0,1-.88,1.25,3.92,3.92,0,0,1-2.65.9,3.87,3.87,0,0,1-2.63-.9,3.24,3.24,0,0,1-.91-1.25,5.16,5.16,0,0,1-.25-1.85v-5.14Z'
      />
      <path
        className='cls-2'
        d='M312.19,100.43h2.06v.82a4.37,4.37,0,0,1,1-.84,2.48,2.48,0,0,1,1.12-.23,2.72,2.72,0,0,1,2.38,1.32,3.08,3.08,0,0,1,2.62-1.32c2,0,3,1.22,3,3.64v5.49h-2.06v-4.93a3.91,3.91,0,0,0-.31-1.81A1.13,1.13,0,0,0,321,102a1.35,1.35,0,0,0-1.24.64,4.11,4.11,0,0,0-.38,2v4.59h-2.07v-4.9c0-1.59-.45-2.38-1.36-2.38a1.39,1.39,0,0,0-1.27.65,4.08,4.08,0,0,0-.39,2v4.59h-2.06Z'
      />
      <path
        className='cls-2'
        d='M325.73,96.73a1.3,1.3,0,0,1,.39-.93,1.28,1.28,0,0,1,.94-.39,1.31,1.31,0,0,1,.95.39,1.28,1.28,0,0,1,.39.94,1.3,1.3,0,0,1-.39,1,1.28,1.28,0,0,1-.94.39,1.33,1.33,0,0,1-1.34-1.35Zm2.36,3.7v8.88h-2v-8.88Z'
      />
      <path
        className='cls-2'
        d='M329.75,100.43h2.06v.82a3.39,3.39,0,0,1,2.43-1.07,3.07,3.07,0,0,1,2.42,1,4,4,0,0,1,.75,2.71v5.44h-2.07v-5a3.31,3.31,0,0,0-.36-1.82,1.48,1.48,0,0,0-1.3-.51,1.59,1.59,0,0,0-1.45.68,4.62,4.62,0,0,0-.42,2.32v4.28h-2.06Z'
      />
      <path
        className='cls-2'
        d='M338.75,96.73a1.35,1.35,0,0,1,1.33-1.32,1.3,1.3,0,0,1,.95.39,1.29,1.29,0,0,1,.4.94,1.31,1.31,0,0,1-.4,1,1.26,1.26,0,0,1-.94.39,1.35,1.35,0,0,1-1.34-1.35Zm2.36,3.7v8.88h-2.05v-8.88Z'
      />
      <path
        className='cls-2'
        d='M347.94,101.94l-1.69.91c-.27-.55-.6-.82-1-.82a.69.69,0,0,0-.48.19.64.64,0,0,0-.2.48c0,.34.39.68,1.18,1A7.26,7.26,0,0,1,348,105a2.22,2.22,0,0,1,.57,1.6,2.82,2.82,0,0,1-.95,2.15,3.28,3.28,0,0,1-2.23.82,3.23,3.23,0,0,1-3.16-2.18l1.75-.81a3.83,3.83,0,0,0,.56.81,1.25,1.25,0,0,0,.89.34c.69,0,1-.31,1-.94,0-.37-.27-.71-.8-1l-.62-.3q-.31-.13-.63-.3a3.78,3.78,0,0,1-1.26-.87,2.17,2.17,0,0,1-.47-1.44,2.52,2.52,0,0,1,.8-1.91,2.76,2.76,0,0,1,2-.76A2.69,2.69,0,0,1,347.94,101.94Z'
      />
      <path className='cls-2' d='M351.54,102.34v7h-2.05v-7h-.88v-1.91h.88V97.17h2.05v3.26h1.6v1.91Z' />
      <path
        className='cls-2'
        d='M361.69,105.35h-6.37a2.77,2.77,0,0,0,.72,1.75,2.17,2.17,0,0,0,1.61.63,2.14,2.14,0,0,0,1.27-.36A5.06,5.06,0,0,0,360,106l1.73,1a6.78,6.78,0,0,1-.85,1.17,4.44,4.44,0,0,1-.95.8,3.89,3.89,0,0,1-1.11.46,5.17,5.17,0,0,1-1.28.15,4.21,4.21,0,0,1-3.2-1.28,4.8,4.8,0,0,1-1.2-3.41,4.9,4.9,0,0,1,1.17-3.41,4.44,4.44,0,0,1,6.22,0,4.93,4.93,0,0,1,1.13,3.44Zm-7.14-9.3,1.92-1L359,98.37l-1.13.69Zm5,7.63a2,2,0,0,0-2.07-1.65,2,2,0,0,0-.7.12,1.77,1.77,0,0,0-.6.33,2,2,0,0,0-.46.51,2.7,2.7,0,0,0-.29.69Z'
      />
      <path
        className='cls-2'
        d='M363,100.43h2.05v.79a4,4,0,0,1,1-.81,2.29,2.29,0,0,1,1.05-.23,3.33,3.33,0,0,1,1.7.53l-.94,1.88a2,2,0,0,0-1.14-.42c-1.12,0-1.68.85-1.68,2.54v4.6H363Z'
      />
      <path
        className='cls-2'
        d='M377,105.35h-6.37a2.71,2.71,0,0,0,.71,1.75,2.19,2.19,0,0,0,1.62.63,2.09,2.09,0,0,0,1.26-.36,5.06,5.06,0,0,0,1.13-1.35l1.73,1a7.36,7.36,0,0,1-.85,1.17,4.75,4.75,0,0,1-1,.8,4,4,0,0,1-1.1.46,5.31,5.31,0,0,1-1.29.15,4.19,4.19,0,0,1-3.19-1.28,4.8,4.8,0,0,1-1.2-3.41,5,5,0,0,1,1.16-3.41,4.46,4.46,0,0,1,6.23,0,4.93,4.93,0,0,1,1.13,3.44Zm-2.11-1.67a2,2,0,0,0-2.07-1.65,2,2,0,0,0-.7.12,1.77,1.77,0,0,0-.6.33,2,2,0,0,0-.46.51,2.14,2.14,0,0,0-.29.69Z'
      />
      <path
        className='cls-2'
        d='M390.07,100.81v2.73a5,5,0,0,0-1.26-1.18,2.41,2.41,0,0,0-1.28-.33,2.51,2.51,0,0,0-1.93.82,2.83,2.83,0,0,0-.76,2,2.91,2.91,0,0,0,.73,2.05,2.49,2.49,0,0,0,1.91.8,2.67,2.67,0,0,0,1.31-.31,5.24,5.24,0,0,0,1.28-1.21v2.71a5.42,5.42,0,0,1-2.5.65,4.84,4.84,0,0,1-3.45-1.33,4.47,4.47,0,0,1-1.38-3.33,4.52,4.52,0,0,1,1.4-3.36,4.78,4.78,0,0,1,3.45-1.37A5.14,5.14,0,0,1,390.07,100.81Z'
      />
      <path
        className='cls-2'
        d='M391.61,93.91h2.05v7.26a3.54,3.54,0,0,1,2.42-1,3.15,3.15,0,0,1,2.41,1,4,4,0,0,1,.76,2.71v5.44H397.2v-5.25a2.54,2.54,0,0,0-.38-1.55,1.46,1.46,0,0,0-1.22-.48,1.68,1.68,0,0,0-1.51.67,4.52,4.52,0,0,0-.43,2.32v4.29h-2.05Z'
      />
      <path
        className='cls-2'
        d='M407.47,100.43h2.06v8.88h-2.06v-.93a3.95,3.95,0,0,1-5.78-.14,5,5,0,0,1-1.19-3.4,4.84,4.84,0,0,1,1.19-3.33,3.89,3.89,0,0,1,3-1.33,3.79,3.79,0,0,1,2.78,1.28Zm-4.87,4.41a3.07,3.07,0,0,0,.68,2.08,2.25,2.25,0,0,0,1.77.81,2.36,2.36,0,0,0,1.84-.78,3.34,3.34,0,0,0,0-4.12,2.32,2.32,0,0,0-1.82-.8,2.25,2.25,0,0,0-1.77.81A2.94,2.94,0,0,0,402.6,104.84Z'
      />
      <path
        className='cls-2'
        d='M411.19,100.43h2.05v.79a4,4,0,0,1,1-.81,2.33,2.33,0,0,1,1.06-.23,3.36,3.36,0,0,1,1.7.53l-.94,1.88a2,2,0,0,0-1.14-.42c-1.12,0-1.68.85-1.68,2.54v4.6h-2.05Z'
      />
      <path
        className='cls-2'
        d='M425.83,109.1c0,.42,0,.79,0,1.11s-.07.6-.11.84a3.87,3.87,0,0,1-.86,1.73,4.17,4.17,0,0,1-3.31,1.39,4.42,4.42,0,0,1-2.93-1,3.93,3.93,0,0,1-1.37-2.73h2.06a2.32,2.32,0,0,0,.39,1,2.2,2.2,0,0,0,1.87.83c1.5,0,2.25-.91,2.25-2.75v-1.24a3.86,3.86,0,0,1-2.82,1.25,3.77,3.77,0,0,1-3-1.32,4.83,4.83,0,0,1-1.17-3.32,5.16,5.16,0,0,1,1.08-3.31,3.78,3.78,0,0,1,3.09-1.44,3.58,3.58,0,0,1,2.79,1.25v-1h2.05Zm-2-4.21a3,3,0,0,0-.69-2.06,2.3,2.3,0,0,0-1.8-.8,2.2,2.2,0,0,0-1.84.87,3.57,3.57,0,0,0,0,4,2.22,2.22,0,0,0,1.84.84,2.25,2.25,0,0,0,1.86-.85A3.06,3.06,0,0,0,423.86,104.89Z'
      />
      <path
        className='cls-2'
        d='M435.58,105.35h-6.37a2.76,2.76,0,0,0,.71,1.75,2.19,2.19,0,0,0,1.62.63,2.09,2.09,0,0,0,1.26-.36,5.06,5.06,0,0,0,1.13-1.35l1.73,1a7.36,7.36,0,0,1-.85,1.17,4.75,4.75,0,0,1-1,.8,3.83,3.83,0,0,1-1.1.46,5.19,5.19,0,0,1-1.29.15,4.19,4.19,0,0,1-3.19-1.28,4.8,4.8,0,0,1-1.2-3.41,4.9,4.9,0,0,1,1.17-3.41,4.44,4.44,0,0,1,6.22,0,4.93,4.93,0,0,1,1.13,3.44Zm-2.11-1.67A2,2,0,0,0,431.4,102a2,2,0,0,0-.7.12,1.77,1.77,0,0,0-.6.33,2,2,0,0,0-.46.51,2.14,2.14,0,0,0-.29.69Zm1-7.63-3.32,3L430,98.37l2.5-3.32Z'
      />
      <path
        className='cls-2'
        d='M448.27,93.91h2.06v15.4h-2.06v-.93a3.85,3.85,0,0,1-2.75,1.19,4,4,0,0,1-3-1.33,5,5,0,0,1-1.19-3.4,4.83,4.83,0,0,1,1.19-3.32,3.84,3.84,0,0,1,3-1.34,3.79,3.79,0,0,1,2.79,1.28Zm-4.87,10.93a3.07,3.07,0,0,0,.68,2.08,2.25,2.25,0,0,0,1.77.81,2.36,2.36,0,0,0,1.84-.78,3.34,3.34,0,0,0,0-4.12,2.32,2.32,0,0,0-1.82-.8,2.25,2.25,0,0,0-1.77.81A2.94,2.94,0,0,0,443.4,104.84Z'
      />
      <path
        className='cls-2'
        d='M460.08,105.35h-6.37a2.71,2.71,0,0,0,.71,1.75,2.19,2.19,0,0,0,1.62.63,2.09,2.09,0,0,0,1.26-.36,5.06,5.06,0,0,0,1.13-1.35l1.73,1a7.36,7.36,0,0,1-.85,1.17,4.75,4.75,0,0,1-1,.8,4,4,0,0,1-1.1.46,5.31,5.31,0,0,1-1.29.15,4.19,4.19,0,0,1-3.19-1.28,4.8,4.8,0,0,1-1.2-3.41,4.9,4.9,0,0,1,1.16-3.41,4,4,0,0,1,3.12-1.29,4,4,0,0,1,3.1,1.25,4.89,4.89,0,0,1,1.14,3.44ZM458,103.68A2,2,0,0,0,455.9,102a2,2,0,0,0-.7.12,1.77,1.77,0,0,0-.6.33,2,2,0,0,0-.46.51,2.14,2.14,0,0,0-.29.69Z'
      />
      <path className='cls-2' d='M468.27,93.91v15.4h-2V93.91Z' />
      <path
        className='cls-2'
        d='M476.48,100.43h2.06v8.88h-2.06v-.93a3.93,3.93,0,0,1-5.77-.14,5,5,0,0,1-1.2-3.4,4.8,4.8,0,0,1,1.2-3.33,3.87,3.87,0,0,1,3-1.33,3.77,3.77,0,0,1,2.78,1.28Zm-4.87,4.41a3.07,3.07,0,0,0,.69,2.08,2.25,2.25,0,0,0,1.77.81,2.36,2.36,0,0,0,1.84-.78,3.38,3.38,0,0,0,0-4.12,2.34,2.34,0,0,0-1.83-.8,2.24,2.24,0,0,0-1.76.81A3,3,0,0,0,471.61,104.84Z'
      />
      <path
        className='cls-2'
        d='M493.39,97.32l-1.73,1a2.93,2.93,0,0,0-.92-1.09,2.07,2.07,0,0,0-1.17-.3,2.16,2.16,0,0,0-1.47.51,1.54,1.54,0,0,0-.59,1.24c0,.68.51,1.24,1.54,1.66l1.4.57a6.07,6.07,0,0,1,2.51,1.7,3.79,3.79,0,0,1,.79,2.45,4.33,4.33,0,0,1-1.29,3.21,4.46,4.46,0,0,1-3.24,1.28,4.37,4.37,0,0,1-3-1.09,5.09,5.09,0,0,1-1.47-3.06l2.15-.48a3.63,3.63,0,0,0,.52,1.72,2.19,2.19,0,0,0,1.91.91,2.21,2.21,0,0,0,1.65-.67,2.28,2.28,0,0,0,.66-1.68,2.23,2.23,0,0,0-.12-.75,1.78,1.78,0,0,0-.35-.63,2.87,2.87,0,0,0-.63-.54,5.48,5.48,0,0,0-.91-.48l-1.36-.56c-1.92-.82-2.89-2-2.89-3.58A3.4,3.4,0,0,1,486.57,96a4.34,4.34,0,0,1,3-1.07A4.21,4.21,0,0,1,493.39,97.32Z'
      />
      <path
        className='cls-2'
        d='M501.54,100.43h2.06v8.88h-2.06v-.93a3.95,3.95,0,0,1-5.78-.14,5,5,0,0,1-1.19-3.4,4.84,4.84,0,0,1,1.19-3.33,3.89,3.89,0,0,1,3-1.33,3.79,3.79,0,0,1,2.78,1.28Zm-4.87,4.41a3.12,3.12,0,0,0,.68,2.08,2.25,2.25,0,0,0,1.77.81A2.36,2.36,0,0,0,501,107a3.34,3.34,0,0,0,0-4.12,2.32,2.32,0,0,0-1.82-.8,2.25,2.25,0,0,0-1.77.81A2.94,2.94,0,0,0,496.67,104.84Z'
      />
      <path
        className='cls-2'
        d='M505.26,100.43h2.06v.82a3.35,3.35,0,0,1,2.42-1.07,3.07,3.07,0,0,1,2.42,1,4,4,0,0,1,.75,2.71v5.44h-2.06v-5a3.21,3.21,0,0,0-.37-1.82,1.46,1.46,0,0,0-1.29-.51,1.58,1.58,0,0,0-1.45.68,4.62,4.62,0,0,0-.42,2.32v4.28h-2.06Z'
      />
      <path className='cls-2' d='M516.68,102.34v7h-2v-7h-.88v-1.91h.88V97.17h2v3.26h1.6v1.91Z' />
      <path
        className='cls-2'
        d='M527,105.35h-6.36a2.71,2.71,0,0,0,.71,1.75,2.19,2.19,0,0,0,1.61.63,2.12,2.12,0,0,0,1.27-.36,5.06,5.06,0,0,0,1.12-1.35l1.74,1a7.36,7.36,0,0,1-.85,1.17,4.75,4.75,0,0,1-1,.8,4,4,0,0,1-1.1.46,5.31,5.31,0,0,1-1.29.15,4.17,4.17,0,0,1-3.19-1.28,4.8,4.8,0,0,1-1.21-3.41,4.9,4.9,0,0,1,1.17-3.41,4,4,0,0,1,3.12-1.29,4,4,0,0,1,3.1,1.25,4.89,4.89,0,0,1,1.13,3.44Zm-2.1-1.67a2,2,0,0,0-2.07-1.65,2.08,2.08,0,0,0-.71.12,1.82,1.82,0,0,0-.59.33,1.87,1.87,0,0,0-.46.51,2.4,2.4,0,0,0-.3.69Zm1-7.63-3.32,3-1.12-.69,2.51-3.32Z'
      />
    </svg>
  );
};
