import { FC } from 'react';

interface BasicButtonProps {
  label: string;
  fullWidth?: boolean;
  template?: string;
  isSubmitButton?: boolean;
  fullRounded?: boolean;
  className?: string;
  onClick?: () => void;
}

export const BasicButton: FC<BasicButtonProps> = ({
  className,
  label,
  fullWidth,
  template = 'green',
  isSubmitButton = false,
  fullRounded,
  onClick,
}) => {
  return (
    <>
      <button
        type={isSubmitButton ? 'submit' : 'button'}
        className={`mt-8 border border-green-primary py-2 font-semibold duration-150 ${className} ${template === 'green' && 'bg-green-primary text-white hover:border-green-secondary hover:bg-green-secondary'} ${template === 'white' && 'bg-white hover:bg-green-light-primary'} ${template === 'orange' && 'border-2 border-transparent bg-orange-primary text-white'} ${template === 'orangeOutline' && 'border-2 border-orange-primary bg-white text-orange-primary'} ${fullWidth ? 'w-full' : 'px-5'} ${fullRounded ? 'rounded-full' : 'rounded-lg'} `}
        onClick={onClick}
      >
        {label}
      </button>
    </>
  );
};

interface DashboardButtonProps {
  type: string;
  onClick?: () => void;
}

export const DashboardButton: FC<DashboardButtonProps> = ({ type, onClick }) => {
  return (
    <>
      <button
        type={type === 'confirm' ? 'submit' : 'button'}
        className={`ml-3 rounded-full px-5 py-2 font-semibold ${type === 'confirm' && 'bg-orange-primary text-white'} ${type === 'cancel' && 'border-2 border-orange-primary text-orange-primary'} `}
        onClick={onClick}
      >
        {type === 'confirm' && 'Confirmer'}
        {type === 'cancel' && 'Annuler'}
      </button>
    </>
  );
};
