export type UserCreate = {
  email: string;
  nickName: string;
  password: string;
  subscribeNewsletter: boolean;
  locationUrl?: string;
};

export type UserInfo = {
  password: string;
  email: string;
  cellphone: string;
  oneTimePasswordActivated: boolean;
  doubleAuthEmailActivated: boolean;
  emailUpdatedAt: Date;
  nickNameUpdatedAt: Date;
  passwordUpdatedAt: Date;
  oneTimePasswordActivedAt: Date;
  doubleAuthEmailActivatedAt: Date;
};

export interface SkeziaError extends Error {
  data: unknown;
  message: string;
  type: string;
}

export type SkeziaAsyncThunkErrorType = {
  rejectValue: SkeziaError;
};

export const isSkeziaError = (error: unknown): error is SkeziaError => {
  return (
    !!error &&
    typeof error === 'object' &&
    //'data' in error &&
    'message' in error &&
    typeof error.message === 'string' &&
    'type' in error &&
    typeof error.type === 'string'
  );
};
