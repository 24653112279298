import React, { ReactNode } from 'react';

interface AuthTabPageHeaderProps {
  title: string;
  description: string | ReactNode;
}

const AuthSettingsItemHeader = ({ title, description }: AuthTabPageHeaderProps) => {
  return (
    <div className='pb-8'>
      <div className='pb-2.5 text-xl font-bold text-blue-primary'>{title}</div>
      <div className='border-b border-gray-tertiary pb-8 text-blue-primary'>{description}</div>
    </div>
  );
};

export default AuthSettingsItemHeader;
